.topo {
  max-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  padding: 5rem 10rem;
}

.descricao {
  text-align: start;
  margin-top: 4rem;
}

.descricao h1 {
  color: #329542;
  font-size: 4rem;
}

.descricao h2 {
  margin-top: -1rem;
}

.descricao p {
  width: 400px;
  margin-top: 1rem;
}

.foto-perfil {
  margin-top: 2rem;
  width: 350px;
  height: 350px;
  box-shadow: 1px 8px 13px -13px #329542;
  border-radius: 50%;
}

.icons-top {
  padding: 50px 0;
  display: flex;
  justify-content: space-evenly;
}

.icons-top h3 {
  margin: auto 0;
}

.icons-top img {
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  -webkit-box-shadow: 0px 10px 5px -8px #329542 0px 9px 20px 0px rgba(50,149,66,0); 
  box-shadow: 0px 10px 6px -8px #329542, 0px 9px 20px 0px rgba(50,149,66,0); 

}

.icons-top img:hover {
  background-color: #329542;
}


@media screen and (max-width: 768px) {
  .topo {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 25rem 0;
  }
  .descricao {
    padding: 20px;
    margin: 0;
  }
  .descricao h1 {
    font-size: 3rem;
  }
  .descricao h2 {
    font-size: 1.5rem;
    margin-top: -.5rem;
  }
  .descricao p {
    width: 80vw;
  }
  .foto-perfil {
    width: 250px;
    height: 250px;
    margin: 0 auto;
  }
  .icons-top {
    padding: 20px 0;
  }
}
