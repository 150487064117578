.footer {
  max-width: 100vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 10rem;
}


.midias-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.midias-footer h1 {
  color: #329542;
  font-size: 2rem;
}

.midias-footer h4 {
  font-size: 1rem;
  margin-top: 8rem;
}

.midias {
  display: flex;
  justify-content: space-between;
}

.midias-footer img {
  width: 30px;
}

.copy {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.copy p {
  padding-top: 3rem;
}

.footer span {
  font-size: .9rem;
  letter-spacing: 1.5px;
}

form {
  min-width: 30%;
  min-height: 250px;
  padding:  1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 1rem;
  box-shadow: 0 0 10px 10px #202020;
}

input {
  padding: .5rem;
  color: #fff;
  border-radius: .4rem;
  border: none;
  background-color: #20202077;
}

input:focus {
  outline: 1px solid #329542;
}

form h4 {
  font-size: 1.2rem;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 991px) {
  .footer {
    padding: 1rem;
  }
  form {
    padding: .5rem;
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .footer {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
  }

  .midias-footer {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .midias-footer h4 {
    margin-top: 1rem;
    margin-bottom: .5rem;
    width: 80%;
  } 
  .midias {
    width: 90%;
    justify-content: space-around;
  }
  .midias img {
    width: 40px;
  }
  form {
    max-width: 400px;
    margin: 1rem auto;
    order: 2;
  }
  .copy {
    order: 3;
  }
  .copy p {
   padding-top: 01rem;
   font-size: 1rem;
  }

  .footer span {
    padding-top: 1rem;
    font-size: .8rem;
  }
}
