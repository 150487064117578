.sobre {
  max-width: 100vw;
  min-height: 100vh;
  padding: 5rem 10rem;
  background-color: #202020;
}

.sobre h1 {
  color: #329542;
  font-size: 1.5rem;
}

.titulo {
  text-align: start;
  padding: 2rem 0;
}

.conteudo p {
  font-size: 1.3rem;
  background-color: #303030;
  padding: 3rem;
  border-radius: 1rem;
  text-align: justify;
  box-shadow: 0 0 10px 0 #303030;
}

@media screen and (max-width: 768px) {
  .sobre {
    padding: 3rem 0 5rem 0;
    height: auto;
  }
  .titulo {
    padding: 2rem;
  }
  .conteudo {
    width: 90vw;
    margin: auto;
  }
  .conteudo p {
    padding: 1rem;
  }
}