.projetos {
  max-width: 100vw;
  min-height: 100vh;
  padding: 5rem 10rem;
  overflow-x: hidden;
}

.projetos h1 {
  color: #329542;
  padding: 0 3rem;
  font-size: 2rem;
}

.paginas-container {
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
}

.pagina {
  margin: 0 auto;
  padding: 1rem;
  width: auto;
}

.pagina h4 {
  font-size: 1.3rem;
}

.pagina img {
  width: 450px;
  box-shadow: 0 0 10px 10px #202020;
}

button:disabled {
  cursor: not-allowed;
  color: #fff;
}

@media screen and (max-width: 800px) {
  .projetos {
    padding: 3rem 0 5rem 0;
  }
  .paginas-container {
    width: 100vw;  
    justify-content: center;
    padding: 10px;
  }  
  .pagina img {
    width: 90vw;
  }
}
