.HamburgerMenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.HamburgerMenu .itens {
  display: none;
  transition: all 0.5s;
}

.iconMenu {
  display: none;
  cursor: pointer;
  z-index: 1;
}

.iconMenu div {
  width: 30px;
  height: 2px;
  background-color: #fff;
  margin: 8px;
  transition: all 0.5s;
}

@media screen and (max-width: 768px) {
  .iconMenu {
    display: block;
  }

  .HamburgerMenu .itens {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 55vw;
    height: 90vh;
    background-color: #303030fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transform: translateX(100%);
  }
}

/*nav bar*/
.HamburgerMenu .itens.active {
  transform: translateX(0);
}

.iconMenu.change .line1 {
  transform: rotate(-45deg) translate(-8px, 8px);
}
.iconMenu.change .line2 {
  opacity: 0;
}
.iconMenu.change .line3 {
  transform: rotate(45deg) translate(-5px, -7px);
}
