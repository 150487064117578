* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  background: #303030;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 1px;
  font-size: 1.2rem;
 
}

button {
  width: 140px;
  height: 40px;
  margin-top: 1rem;
  border: none;
  color: #fff;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid #329542;
  padding: 5px 20px;
  cursor: pointer; 
  transition: 0.5s;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(50,149,66,0.44); 
  box-shadow: 0px 0px 20px 0px rgba(50,149,66,0.44);

}

button:hover {
  background-color: #329542;
  scale: 1.05;
}

a {
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
  font-size: 1.2rem;
}

a:hover {
  opacity: 1;
}

li {
  list-style: none;
}



.js-scroll {
  opacity: 0;
  transform: translate3d(30px, 0, 0);
  transition: 1s;
}

.js-scroll.ativo {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.js-scroll2 {
  opacity: 0;
  transform: translate3d(-30px, 0, 0);
  transition: 1s;
}

.js-scroll2.ativo {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 500px) {
  .js-scroll {
    transform: translate3d(-30px, 0, 0);
  }
  
}